import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Typography,Fab,Toolbar,TextField} from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import AddIcon from '@material-ui/icons/Add';

import SearchIcon from '@material-ui/icons/Search';

import Paper from '@material-ui/core/Paper';
import ReactTable from './shares/react_table';
import classNames from 'classnames';

import {styles} from '../layout/theme';

export class CategoryHiringList extends Component {
  constructor(props){
    super(props);
    this.state = {
      keyword: '',
      searchClick: false
    }
  }
  handleSearch(e){
    var el = document.getElementById('keyword');
    this.setState({ keyword: el.value.trim() });
  }
  handleAdd(){
    this.context.router.history.push('/career/add');
  }
  render(){
    const classes = this.props.classes;
    var source = {
      thead: [{title:'Tiêu đề',value:'nameVN'}, { title: 'Title', value: 'nameEN' }],
      tbody: function (n) {
        return ({
          _id: n._id,
          nameVN: n.nameVN,
          nameEN: n.nameEN
        })
      }
    };
    return(
      <div className="list-page">
        <div className={classNames(classes.auto_toggle, this.state.failed && classes.run)}>
          <Typography type="body2">{this.state.message}</Typography>
        </div>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Danh mục ngành nghề</Typography>
        </Toolbar>
        <Paper className={classes.paper}>
          <Toolbar className={classes.tableToolbar}>
            <FormGroup className={classes.formRowToolbar}>
              <TextField type="search" id="keyword" label="Tìm kiếm" className={classes.textField + ' form-control-no-bottom'} value={this.state.keyword} onChange={(event)=>this.setState({keyword:event.target.value.trim()})}
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}/>
            </FormGroup>
            <Fab color="primary" aria-label="search" className={classes.button} onClick={(e)=>this.handleSearch(e)}>
              <SearchIcon />
            </Fab>
            <Fab color="primary" aria-label="add" className={classes.button} onClick={()=>this.handleAdd()}>
              <AddIcon />
            </Fab>
          </Toolbar>
          <ReactTable apiList='careers' apiSingle='career' apiTable='careers' router={this.context.router} dataMap={source} keyword={this.state.keyword} isSearch={this.state.searchClick} >
          </ReactTable>
        </Paper>
      </div>
    )
  }
}

CategoryHiringList.propTypes = {
  classes: PropTypes.object.isRequired,
};
CategoryHiringList.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(CategoryHiringList);
